<script setup lang="ts">
import RedesignNavBar from '~/pages/components/new/RedesignNavBar.vue'

useHead({
    bodyAttrs: {
        class: 'bg-white'
    },
    htmlAttrs: {
        class: 'bg-white'
    }
})

</script>

<template>
    <div>
        <ClientOnly>
            <RedesignNavBar />
        </ClientOnly>
        <div>
            <slot />
        </div>
    </div>
</template>
